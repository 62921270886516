<template>
    <div v-cloak>
        <PageLoader v-if="isLoading"></PageLoader>

        <div class="report-container column-report">
            <div class="mt-10">
            </div>

            <div class="row my-20 justify-content-between mr-3">
                <div></div>
                <div class="d-flex justify-content-end">
                    <span class="text-white p-2 mx-2 align-self-md-center _price-backup"
                        style="background-color:#0b36dc">Verified</span>
                    <span class="text-white p-2 mx-2 align-self-md-center _price-backup"
                        style="background-color:#FF6F16">Semi-Verified</span>
                    <div class="text-white bg-danger p-2 mx-2 align-self-md-center _price-backup">Estimated</div>
                </div>
                <div class="fields-toggle-wrapper" v-if="custom_header_field">
                    <div class="fields-toggle-block selected-fields">
                        <div class="row justify-content-between">
                            <div class="fields-toggle-title">Shown Data Point</div>
                            <font-awesome-icon
                                style="font-size: 20px;cursor: pointer;position: absolute;top: 12px;right: 12px;"
                                class="text-danger" @click="custom_header_field = false"
                                :icon="['fas', 'times-circle']" />
                        </div>
                        <draggable class="list-group row justify-content-center" :list="fields" group="field"
                            @change="fieldsOn" :sort="true">
                            <template v-for="(element, index) in fields">
                                <div :class="{ 'd-none': ['project', 'selected'].includes(element.label.toLocaleLowerCase()) }"
                                    class="list-group-item _cursor-pointer" :key="'element-a123' + element.label">
                                    {{ --index }}. {{ element.label }}</div>
                            </template>

                        </draggable>
                    </div>
                    <div class="fields-toggle-bar"></div>
                    <div class="fields-toggle-block">
                        <div class="fields-toggle-title">Drag and Drop Data Points</div>
                        <draggable class="list-group row" :list="list2" group="field" :sort="true">
                            <div class="list-group-item _cursor-pointer" v-for="(element) in list2"
                                :key="'element-a234' + element.label">{{
                    element.label }}</div>
                        </draggable>
                    </div>
                </div>
            </div>
            <div class="row my-4 ml-1 justify-content-between align-items-center">
                <div></div>
            </div>
            <div class="row mb-3 justify-content-between">
                <div class="col-md-5">
                    <h1 class="ml-3 text-vb-secondary">UNIT REPORT</h1>
                </div>
                <div class="col-md-7">
                    <div class="row mr-4 align-items-center justify-content-end">
                        <div class="col-md-6">
                            <b-form-input v-model="filterSearch" placeholder="Filter project"></b-form-input>
                        </div>
                        <div class="mt-0">
                            <b-button class="mx-1 mx-md-2" variant="vb-tertiary" size="sm" pill
                                @click="refreshResults">UPDATE
                                TABLE</b-button>
                            <img class="print-none mx-2 mt-10" src="/images/assets/fields.png"
                                @click="custom_header_field = !custom_header_field">
                            <img v-if="loading == false" @click="generateReport();" style="cursor: pointer; margin-right: 20px;" width="50" height="50" src="/images/assets/export_icon.png">
                            <b-button v-if="loading == true" variant="vb-primary" disabled>
                                <b-spinner small type="grow"></b-spinner>
                                Generating report...
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <RpdTable id="my-table" v-model="totalRows" :totalRows="totalRows" :filter="filterSearch"
                :current-page="currentPage" :per-page="perPage" v-on:popHistory="popHistory"
                v-on:selectAllRows="selectAllRows" v-on:countProjectSelected="countProjectSelected" v-on:popModal="showModal" v-on:updateTotalRows="updateTotalRows"
                :row_data="mlsData" :header_fields="fields" :marketRentalPsfToDouble="marketRentalPsfToDouble">
            </RpdTable>
            <div style="margin-bottom: 8rem !important;" class="row jutify-content-space-between">
                <div class="col">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" prev-text="Prev"
                    first-text="First" last-text="Last" size="md">
                        <template #next-text><font-awesome-icon :icon="['fas', 'caret-right']" /></template>
                        <template #prev-text><font-awesome-icon :icon="['fas', 'caret-left']" /></template>
                    </b-pagination>
                </div>
                <div class="px-3"> 
                    <b-form-select v-model="perPage" :options="rowOptions" class="mt-15"></b-form-select>
                </div>
            </div>
            <b-modal ref="floorplan_modal" header-bg-variant="vb-secondary" title="Floor Plan"
                v-for="(row, key) in mappedRow" :id="'mls-fp-' + row.field_mls_floor_plan_fid" hide-footer :key="key">
                <div v-if="row.floor_plan.fid" class="d-block text-center">
                    <p>
                        <a target="_blank" :href="row.floor_plan.link"><img class='floorplan-pdf'
                                :src="row.floor_plan.preview"></a>
                    </p>
                </div>
            </b-modal>
        </div>
        <b-modal id="mlspop" hide-footer @hidden="mlsHistory = null" title="Unit History">
            <div class="_rpd_typo">
                <div class="row">
                    <div class="col-3">Unit#: {{ unitHistoryData.field_mls_address_value }}</div>
                    <div class="col-3">List Date: <b-badge class="bg-vb-secondary text-light"
                            style="font-size: medium;">{{
                    formatDate(unitHistoryData.field_mls_list_date_value) }}</b-badge></div>
                </div>
                <div class="row">
                    <div class="col-3">Beds: {{ unitHistoryData.field_mls_beds_value }}</div>
                    <div class="col-3">Baths: {{ unitHistoryData.field_mls_baths_value }}</div>
                    <div class="col-3">Unit Size: {{ unitHistoryData.field_mls_unit_size_value }}</div>
                    <div class="col-3">FP Specs: {{ unitHistoryData.fp_specs }}</div>
                </div>
                <div class="row">
                    <div class="col-3">View: {{ unitHistoryData.view_orientation }}</div>
                    <div class="col-3">Levels: {{ unitHistoryData.floor_levels }}</div>
                    <div class="col-3">Parking: {{ unitHistoryData.parking ? unitHistoryData.parking : '-' }}</div>
                    <div class="col-3">Stalls: {{ unitHistoryData.stalls ? unitHistoryData.stalls : '-' }}</div>
                </div>
            </div>
            <table class="table mt-3">
                <thead class="fp-header">
                    <template v-if="isAdmin">
                        <th>List Price</th>
                        <th>Beds</th>
                        <th>Baths</th>
                        <th>$PSF</th>
                        <th>Sold Price</th>
                        <th>SP SQFT</th>
                        <th>Sold Date</th>
                        <th>Status</th>
                        <th>Collection Type</th>
                    </template>
                    <template v-else>
                        <th>Status</th>
                        <th>List Price</th>
                        <th>$PSF</th>
                        <th>Price Date</th>
                        <th>DOM</th>
                        <th>Sold Date</th>
                    </template>
                </thead>
                <tbody class="fp-body">
                    <tr v-for="(row, index) in mlsHistory" style="cursor: pointer;" :key="index">
                        <template v-if="isAdmin">
                            <td>{{ row.mls_list_price > 0 ? '$' + (row.mls_list_price | numFormat) : '-' }}</td>
                            <td>{{ row.bed }}</td>
                            <td>{{ row.baths }}</td>
                            <td>{{ row.mls_psf > 0 ? '$' + (row.mls_psf | numFormat) : '-' }}</td>
                            <td>{{ row.mls_sold_price }}</td>
                            <td>{{ row.mls_sp_sqft }}</td>
                            <td>{{ row.mls_sold_date }}</td>
                            <td>{{ row.mls_status }}</td>
                            <td>{{ row.collection_type }}</td>
                        </template>
                        <template v-else>
                            <td>{{ row.mls_status }}</td>
                            <td>{{ row.mls_list_price > 0 ? '$' + (row.mls_list_price | numFormat) : '-' }}</td>
                            <td>{{ row.mls_psf > 0 ? '$' + (row.mls_psf | numFormat) : '-' }}</td>
                            <td>{{ row.price_date.length > 0 ? row.price_date : '-' }}</td>
                            <td>{{ row.mls_dom > 0 ? row.mls_dom : '-' }}</td>
                            <td>{{ row.mls_sold_date }}</td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </b-modal>
        <!-- style="position: absolute; top:-20rem;" -->
        <ReportFooter id="erl-div" :userData="user_data" style="position: absolute; top:-20rem;"/>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { BACKEND_URL } from '../variables.js';
import { BTabs, BTab, BPopover, BPopoverDirective, BModal, BModalDirective, VBModal, BIconFileEarmarkXFill } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { cloneDeep } from 'lodash';
import { useRequestCounter } from '../store/RequestCounter'
import draggable from 'vuedraggable';
import RpdTable from './common/RpdTable.vue';
import { useUserStore } from '../store/UserStore';
import { useUserDataStore } from '../store/UserDataStore';
import { mapState } from 'pinia';
import { BBadge } from 'bootstrap-vue'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { BSpinner } from 'bootstrap-vue'
import ReportFooter from './common/ReportFooter.vue';
import { text } from '@fortawesome/fontawesome-svg-core';
import html2canvas from 'html2canvas'; 
import PageLoader from '../components/common/PageLoader.vue'
export default {
    components: {
        DatePicker,
        'b-tabs': BTabs,
        'b-tab': BTab,
        'b-popover': BPopover,
        'b-modal': BModal,
        'b-badge': BBadge,
        'b-spinner': BSpinner,
        Treeselect,
        draggable,
        RpdTable,
        ReportFooter,
        PageLoader
    },
    directives: {
        'b-popover': BPopoverDirective,
        'b-modal': VBModal,
    },
    data() {
        return {
            isLoading: false,
            rowOptions: [
                { value: 10, text: '10 rows' }, 
                { value: 25, text: '25 rows' }, 
                { value: 50, text: '50 rows' }, 
                { value: 100, text: '100 rows' }, 
            ],
            projectSelected: 0,
            arrayOfImages:[],
            loading:false,
            query: this.$route.query,
            unitHistoryData: {},
            mlsData: [],
            sortBy: 'sort-uname',
            orderAsc: true,
            mlsHistory: [],
            filterSearch: null,
            fields: [],
            list2: [
                { label: "Balcony", key: 'balcony' },
                { label: "Balcony Area", key: 'balcony_area' },
                { label: "View Orientation", key: 'view_orientation' },
                { label: "Parking", key: 'parking' },
                { label: "Stalls", key: 'stalls' },
                { label: "Buyer Incentives", key: 'buyer_incentives' },
                { label: "Realtor Incentives", key: 'realtor_incentives' },
                { label: "Net Price", key: 'net_price' },
                { label: "Floor Level", key: 'floor_levels' },
                { label: "FP Specs", key: 'fp_specs' },
                { label: "Sold Date", key: 'sold_date' },
                { label: "Sold Price", key: 'sold_price' },
                { label: "Status", key: 'status_name_name' }
            ],
            custom_header_field: false,
            allSelected: false,
            filteredReportDetail: [],
            selectedSummary: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            useUserDataStore: useUserDataStore()
        }
    },
    methods: {
            countProjectSelected(data){
                if(data)
                    this.projectSelected++
                else
                    this.projectSelected--

                const el = this.$createElement
                
                if(this.projectSelected <= 10){
                    const vNodesMsg = el(
                        'p',
                        { class: ['text-reset', 'mb-0'] },
                        [ 
                            el('strong', this.projectSelected + ''), 
                            ' project selected'
                        ]
                    )
                    this.$bvToast.toast([vNodesMsg], {
                        title: '',
                        variant: 'info', 
                        solid: true,  
                        autoHideDelay: 2000
                    })
                }else{
                    this.$bvToast.toast(`You can select atleast 10 projects`, {
                        title: 'Selected projects exceeded',
                        variant: 'warning', 
                        solid: true,  
                    }) 
                }
            },
            async fetchUserData() {
                try {
                    await this.useUserDataStore.getData();
                } catch (error) {
                    console.error('Failed to fetch user data:', error);
                }
            },
            async generateReport () {
            let vm = this 
            
            let summaryReport = vm.mlsData
            const el = this.$createElement
            const vNodesMsg = el(
                    'p',
                    { class: ['text-reset', 'mb-0'] },
                    [
                        'Max of',
                        el('strong', ' 10 projects '),
                        'only'
                    ]
                    )

            if(summaryReport.length > 10){
                this.$bvToast.toast([vNodesMsg], {
                    title: 'Something went wrong',
                    variant: 'warning', 
                    solid: true,  
                })
                vm.loading = false
                return
            }

            vm.loading = true
            let date = new Date();
            let formattedDate = vm.reportFormatDate(date)
            let logo = document.createElement('img');
            logo.src = 'src/assets/images/viber_image_2023-11-07_11-30-39-588.jpg';
            let rpdBackground = new Image()
            rpdBackground.src = '/images/assets/logo/RPD_logomark_black-1_faded.png'
            rpdBackground.width = 150
            rpdBackground.height = 150

            const doc = new jsPDF();
            
            let floorplanCount = 0;
            const pageWidth = doc.internal.pageSize.width;
            const pageHeight = doc.internal.pageSize.height;
            doc.setFontSize(8)

            doc.text(formattedDate, 15, 10);
            doc.text("realpropertydata.ca", doc.internal.pageSize.width - 38, 10)
            doc.setLineWidth(0.5);
            doc.setDrawColor("#d9d9d9");
            doc.line(15, 20, doc.internal.pageSize.width - 15, 20);
            doc.setFontSize(14)
            doc.setFont("Helvetica", "bold")
            doc.text("Unit Summary Report", 15, 30) 
            doc.setLineWidth(0);
            doc.setDrawColor("#000");
            doc.line(15, 31, 67, 31);
            doc.setFontSize(12)
            let unitBreakDownHeaders = vm.fields.filter(x => x.key !== 'checkbox').map((item) => {return {...item}})
           
            const formattedSummary = summaryReport.map( (item) => {
                return {...item}
            } );
            
            const tableData = formattedSummary.map((item, index) => {
                return unitBreakDownHeaders.map(header => {
                    if (header.key == 'customData')
                        return `${item[header.key].projectName}` + '\n' + item[header.key].project

                        if (header.key == 'field_mls_list_price_value' || header.key == 'psf') {
                        let formatPrice = Math.round(Number(item[header.key])).toLocaleString();
                        return item[header.key] ? `$${formatPrice}` : '-';
                    }
                    
                    return item[header.key] ? item[header.key] : '-';
                })
            })
             
            var htmlElement = document.getElementById("erl-div"); 
            const canvas = await html2canvas(htmlElement, {
                        onrendered: function(canvas)
                        {
                            document.body.appendChild(canvas);
                        },
                        useCORS: true,
                        logging:true
            }); 
            const toImage = canvas.toDataURL('image/png')
            let toImageWidth = null;
            let toImageHeight = null;
            let toImageSrc = null;
            floorplanCount = this.arrayOfImages.length + 1;
            await this.getImageRatio(toImage).then(resolve => {  
                          
                        if(this.user_data.first_name != null)
                        {
                            toImageWidth = resolve.image.width
                            toImageHeight = resolve.image.height
                            toImageSrc = resolve.image.src
                            doc.addImage(resolve.image.src, 'png', 15, pageHeight - 60, resolve.image.width + 47, resolve.image.height + 6)
                        }
                    
                        autoTable(doc, {
                        startY: 50, 
                        headStyles: { fillColor: '#476E93', cellPadding: {top:3,bottom: 5, left: 3 } , valign:'center' }, 
                        columnStyles: { 0: {halign: 'left', valign:'top',cellPadding: {top:3,bottom: 3, left: 3 } }, cellPadding: {top:0 }  }, 
                        styles: { fontSize: 8, halign:'center', valign:'center'}, 
                        head: [unitBreakDownHeaders.map(header => header.label)], body: tableData,
                        didParseCell: async(tableHeader) => {
                            if(tableHeader.cell.raw == 'Project')
                                tableHeader.cell.styles.halign = 'left'
                        },
                        didDrawPage: async (data) => { 
                            const centerX = pageWidth / 2 - rpdBackground.width / 2;
                            const centerY = pageHeight / 2 - rpdBackground.height / 2;
                            doc.addImage(rpdBackground, 'PNG', centerX, centerY - 26, rpdBackground.width, rpdBackground.height);
                            doc.addImage(logo, 'PNG', data.settings.margin.left, doc.internal.pageSize.height - (data.settings.margin.bottom + 8),50,10); 
                            
                            doc.setFontSize(8)
                            doc.setFont("Helvetica", "normal")
                            doc.text('page 1 of ' + (floorplanCount), pageWidth - 30,pageHeight - 15)
                        },
                    })    
            }) 
                const promise2 = new Promise((resolve) => {
                    vm.arrayOfImages.forEach((x, index) => {
                    doc.addPage("a4", 'p')
                    let currentPage = index += 2
                    const centerX = pageWidth / 2 - x.image.width / 2;
                    const centerY = pageHeight / 2 - x.image.height / 2; 

                    doc.setTextColor("#000000");
                    doc.setFontSize(12)
                    doc.setFont("Helvetica", "bold")
                    doc.text(x.project.name, 15, 30);
                    
                    doc.setFontSize(10)
                    doc.setFont("Helvetica", "normal")
                    doc.text('Unit #: ', 15, 35);
                    doc.text(x.project.address, 35, 35);
                    doc.text('Price: ', 15, 40);
                    doc.text(x.project.price, 35, 40);
                    doc.text('PSF: ', 15, 45);
                    doc.text(x.project.psf, 35, 45);
                    doc.text('Price Date: ', 15, 50);
                    doc.text(x.project.priceDate, 35, 50); 
                    doc.addImage(x.image.src, centerX, centerY, x.image.width, x.image.height)
                    
                    doc.addImage(toImageSrc, 'png', 15,  pageHeight + 30, toImageWidth + 47, toImageHeight + 6)
                    doc.addImage(logo, 'PNG', 10, pageHeight - 25, 50,10);   
                    
                    doc.setFontSize(8)
                    doc.text('page ' + currentPage + ' of ' + (floorplanCount), pageWidth - 30,pageHeight - 15)

                    return resolve("Images loaded")
                
                    });
                })
                Promise.all([promise2]).then((values) => {
                    this.loading = false
                    window.open(doc.output('bloburl'), '_blank',"toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=500,width=1000,height=800");

                });
            },

            async getImageRatio(imgUrl) { 
                        
                    const max = {height:190,width:133} 

                    return new Promise(async (resolve) => {
                        let image = new Image(); 
                        image.src=imgUrl
                        
                        image.onload = async function () { 
                        const ratio=image.height/image.width;
                   
                        if(image.height>max.height||image.width>max.width){
                            if(image.height>image.width){
                                image.height=max.height;
                                image.width=image.height*(1/ratio);
                            // Making reciprocal of ratio because ration of height as width is no valid here needs width as height
                            }else if(image.width > image.height){
                                image.width=max.width;
                                image.height=image.width*ratio;
                            // Ratio is valid here 
                            }
                         }    
                            resolve({
                                status: 'success',
                                image: image
                            })
                        }
                    })
                    
                     
            },
            reportFormatDate(dateVal) {
                    let vm = this
                    var newDate = new Date(dateVal);

                    var sMonth = vm.padValue(newDate.getMonth() + 1);
                    var sDay = vm.padValue(newDate.getDate());
                    var sYear = newDate.getFullYear();
                    var sHour = newDate.getHours();
                    var sMinute = vm.padValue(newDate.getMinutes());
                    var sAMPM = "AM";

                    var iHourCheck = parseInt(sHour);

                    if (iHourCheck > 12) {
                        sAMPM = "PM";
                        sHour = iHourCheck - 12;
                    }
                    else if (iHourCheck === 0) {
                        sHour = "12";
                    }

                    sHour = vm.padValue(sHour);

                    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
            },
            
        padValue(value) {
                return (value < 10) ? "0" + value : value;
        },
        formatDate(date) {
            if (!date || date.length <= 0)
                return '-';

            let mlsDate = new Date(date * 1000);

            return mlsDate.toISOString().slice(0, 10).replace(/-/g, '-');
        },
        setRpdHeaderFields() {
            const vm = this
            
            switch(vm.current_user.role){
                case 'realtor': 
                    vm.fields = [
                        { label: 'Selected', key: 'checkbox', thClass: 'w-small' },
                        { label: "Project", key: 'customData', thClass: 'text-left pl-3 w-25', sortable: true },
                        { label: "Location" , key: 'customLocationData',sortable: true },
                        { label: "Unit #", key: 'field_mls_address_value', sortable: true },
                        { label: "Beds", key: 'field_mls_beds_value',sortable: true },
                        { label: "Baths", key: 'field_mls_baths_value',sortable: true },
                        {
                            label: "Unit Size", key: 'field_mls_unit_size_value', formatter: (value) => {
                                return Number(value) ? Math.round(value).toLocaleString() : '-'
                            },sortable: true
                        },
                        { label: "Price", key: 'field_mls_list_price_value', formatter: this.roundToWholeNumber, sortable: true },
                        { label: "$PSF", key: 'psf', sortable: true },
                        { label: "Price Date", key: 'price_date', sortable: true }
                    ]
                break;
                
                case 'admin':
                vm.fields = [
                    { label: 'Selected', key: 'checkbox', thClass: 'w-small' },
                    { label: "Project", key: 'customData', thClass: 'text-left pl-3 w-25', sortable: true },
                    { label: "Location" , key: 'customLocationData',sortable: true },
                    { label: "Unit #", key: 'field_mls_address_value', sortable: true },
                    { label: "Beds", key: 'field_mls_beds_value' },
                    { label: "Baths", key: 'field_mls_baths_value' },
                    {
                        label: "Unit Size", key: 'field_mls_unit_size_value', formatter: (value) => {
                            return Number(value) ? Math.round(value).toLocaleString() : '-'
                        }
                    },
                    { label: "Price", key: 'field_mls_list_price_value', formatter: this.roundToWholeNumber, sortable: true },
                    { label: "$PSF", key: 'psf', sortable: true },
                    { label: "Price Date", key: 'price_date', sortable: true }
                ]
                break;

                default: 
                    vm.fields = [
                        { label: 'Selected', key: 'checkbox', thClass: 'w-small' },
                        { label: "Project", key: 'customData', thClass: 'text-left pl-3 w-25', sortable: true },
                        { label: "Location" , key: 'customLocationData',sortable: true },
                        { label: "Unit #", key: 'field_mls_address_value', sortable: true },
                        { label: "Beds", key: 'field_mls_beds_value',sortable: true },
                        { label: "Baths", key: 'field_mls_baths_value',sortable: true },
                        {
                            label: "Unit Size", key: 'field_mls_unit_size_value', formatter: (value) => {
                                return Number(value) ? Math.round(value).toLocaleString() : '-'
                            },sortable: true
                        },
                        { label: "Price", key: 'field_mls_list_price_value', formatter: this.roundToWholeNumber, sortable: true },
                        { label: "$PSF", key: 'psf', sortable: true },
                        { label: "Price Date", key: 'price_date', sortable: true }
                    ]
                break;

            }
            
        },
        selectAllRows({ selectAll, tableRef }) {
            Object.keys(this.mlsData)
                .forEach(item => {
                    this.mlsData[item].rowSelected = selectAll
                })
        },
        updateTotalRows(value) {
            this.totalRows = value
        },
        roundToWholeNumber(value) {
            return Number(value) ? '$' + Math.round(value).toLocaleString() : '-'
        },
        showModal(data) {
            this.$root.$emit('bv::show::modal', 'mls-fp-' + data)
        },
        setColor(data) {
            let color = ""
            if (data) {
                if (data == 'Verified')
                    color = "#0b36dc"

                if (data == "Educated Estimated")
                    color = "#dc3545"

                if (data == "Semi-Verified")
                    color = "#FF6F16"

            }
            return color
        },
        refreshResults() {
            if(this.projectSelected > 10) {
                this.countProjectSelected
                this.$bvToast.toast(`You can select atleast 10 projects`, {
                        title: 'Selected projects exceeded',
                        variant: 'warning', 
                        solid: true,  
                }) 
                return
            }
            this.arrayOfImages = new Array();
            if (this.mlsData.filter(row => row.rowSelected === true).length > 0)
                this.mlsData = this.mlsData.filter(row => row.rowSelected === true)
                this.filteredFloorPlan.forEach(x => {
                        let image = new Image()
                        image.src = x.floor_plan.preview
                        let compressedImage = this.getImageRatio(image.src)
                        compressedImage.then(resolve => {
                            if(resolve.status == "success"){

                                this.arrayOfImages.push(
                                    {   project: {
                                        name: x.name,
                                        address: x.field_mls_address_value, 
                                        price: this.roundToWholeNumber(x.field_mls_list_price_value).toString() ,
                                        psf: x.psfToString,
                                        priceDate: x.price_date
                                        },
                                        image: resolve.image
                                    }
                                )
                            }
                        })
                })
            return
        },
        selectAll: function () {
            if (!this.allSelected) {
                for (let report in this.mlsSort) {
                    this.selectedSummary.push(this.mlsSort[report].entity_id);
                }
            }
            else {
                this.selectedSummary = [];
            }
        },
        fieldsOn: function () {
            this.reportFields = [];
            if (this.fields.length >= 10) {
                let poped = this.fields.pop();
                this.list2.push(poped);
            } else {
                this.fields.forEach((val, key) => {
                    this.reportFields.push(val.field);
                });
            }
        },
        project_sort(column) {
            if (this.sortBy == column) {
                this.orderAsc = !this.orderAsc;
            }
            this.sortBy = column;
        },
        utcLink(utc) {
            let link = '/type?project=' + utc.city_entity_id + '&fid=' + utc.field_project_type_collection_value + '&sid=' + utc.field_utc_status_tid + '&uid=' + utc.field_unit_type_value;
            return link;
        },
        getMLSDetail(params) {
            const endPoint = `api/get_mls_list?${params}`;
            this.isLoading = true
            useRequestCounter().incrementRequestCounter();
            $.get({
                url: BACKEND_URL + endPoint,
            }, (data, status) => {
                if (status !== 'success') return;
                // this.mlsData = cloneDeep(data); 
                this.mlsData = data.filter(mlsRow => mlsRow.price_backup != 'Educated Estimated' && mlsRow.price_date.length > 0).map(mlsRow => {
                    return {
                        ...mlsRow,
                        psfToString: this.roundToWholeNumber(mlsRow.psf).toString(),
                        customData: {
                            project: mlsRow.developer,
                            projectName: mlsRow.name,
                            href_link: this.utcLink(mlsRow)
                        }, 
                        customLocationData:{
                            address: mlsRow.address,
                            municipality: mlsRow.city,
                            neighbour: mlsRow.neighbour
                        },
                        rowSelected: false
                    };
                })
                this.isLoading = false
                useRequestCounter().incrementCompletedRequest();
            });
        },
        popHistory(data) {
            const vm = this
            vm.unitHistoryData = data;
            let entry = 'eid=' + data.entity_id;
            const endPoint = `api/get_mls_history?${entry}`;
            $.get({
                url: BACKEND_URL + endPoint,
            }, (data, status) => {
                if (status !== 'success') return;
                this.mlsHistory = data;
            });
        }
    },
    computed: {
        ...mapState(useUserStore, ['current_user']),
        ...mapState(useUserDataStore, ['user_data']),
        marketRentalPsfToDouble: function() { 
            const vm = this;
                if(vm.current_user.role == "developer" && this.$router.currentRoute.fullPath.includes('mls-list') && this.$route.query['unittype[]'] == "2954")
                    return true

                    return false
        },
        isAdmin: function () {
            return this.current_user.role == 'admin'
        },
        filteredFloorPlan: function(){
            return this.mlsData.filter(x => x.floor_plan.length != 0)
        },
        mappedRow: {
            get: function () {
                return this.mlsData.map(mlsRow => {
                    return {
                        ...mlsRow,
                        customData: {
                            project: mlsRow.developer,
                            projectName: mlsRow.name,
                            href_link: this.utcLink(mlsRow)
                        },
                        rowSelected: false
                    };
                })
            },
            set: function (newVal) {
                console.log(newVal)
                return newVal
            }
        },
        mlsSort: {
            get: function () {
                let alphSorting = {
                    'sort-uname': 'name',
                    'location': 'address',
                    'sort-status': 'status_name_name',
                    'sort-unit': 'field_mls_address_value',
                    'sort-beds': 'field_mls_beds_value',
                    'sort-baths': 'field_mls_baths_value',
                    'sort-usize': 'field_mls_unit_size_value',
                    'sort-price': 'price',
                    'sort-psf': 'psf',
                    'sort-date': 'price_date'
                }
                var vm = this;
                var ob = this.mlsData;
                Object.keys(alphSorting).forEach(function (k) {
                    if (k == vm.sortBy) {
                        if (vm.orderAsc) {
                            ob = vm.mlsData.sort((a, b) => (a[alphSorting[k]] > b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] > a[alphSorting[k]]) ? -1 : 0));
                        }
                        else {
                            ob = vm.mlsData.sort((a, b) => (a[alphSorting[k]] < b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] < a[alphSorting[k]]) ? -1 : 0));
                        }
                    }
                });
                return ob;
            },
            set: function (newValue) {
                this.mlsData = newValue
            }

        }
    },
    watch: {
        mlsData: function (newVal) {
            this.totalRows = newVal.length
        },
    },
    mounted: function () {

        let param = ''
        var vm = this;
        vm.fetchUserData();
        let rangList = [
            'mlsbedsmax',
            'mlsbedsmin',
            'mlsbathsmax',
            'mlsbathsmin',
            'mlspricemax',
            'mlspricemin',
            'mlspricemax',
            'mlsunitmax',
            'mlsunitmin'
        ];
        vm.setRpdHeaderFields()
        rangList.forEach((d) => {
            if (vm.query[d]) {
                param += d + '=' + vm.query[d] + '&';
            }
        });
        let dateList = [
            'listmlsdate',
            'soldmlsdate'
        ];
        dateList.forEach((d) => {
            if ($.isArray(vm.query[d + '[]'])) {
                vm.query[d + '[]'].forEach((a) => {
                    param += d + '[]=' + a + '&';
                });
            }
            else if (vm.query[d + '[]']) {
                param += d + '[]=' + vm.query[d + '[]'] + '&';
            }
        });

        if ($.isArray(vm.query['gut[]'])) {
            vm.query['gut[]'].forEach((a) => {
                param += 'gut[]=' + a + '&';
            });
        }
        else {
            if (vm.query['gut[]']) {
                param += 'gut[]=' + vm.query['gut[]'] + '&';
            }
        }

        if ($.isArray(vm.query['fp[]'])) {
            vm.query['fp[]'].forEach((a) => {
                param += 'fp[]=' + a + '&';
            });
        }
        else {
            if (vm.query['fp[]']) {
                param += 'fp[]=' + vm.query['fp[]'] + '&';
            }
        }

        if ($.isArray(vm.query['city[]'])) {
            vm.query['city[]'].forEach((a) => {
                param += 'city[]=' + a + '&';
            });
        }
        else {
            if (vm.query['city[]']) {
                param += 'city[]=' + vm.query['city[]'] + '&';
            }
        }


        if ($.isArray(vm.query['unittype[]'])) {
            vm.query['unittype[]'].forEach((a) => {
                param += 'unittype[]=' + a + '&';
            });
        }
        else {
            if (vm.query['unittype[]']) {
                param += 'unittype[]=' + vm.query['unittype[]'] + '&';
            }
        }

        if ($.isArray(vm.query['type[]'])) {
            vm.query['type[]'].forEach((a) => {
                param += 'type[]=' + a + '&';
            });
        }
        else {
            if (vm.query['type[]']) {
                param += 'type[]=' + vm.query['type[]'] + '&';
            }
        }

        if ($.isArray(vm.query['status[]'])) {
            vm.query['status[]'].forEach((a) => {
                param += 'status[]=' + a + '&';
            });
        }
        else {
            if (vm.query['status[]']) {
                param += 'status[]=' + vm.query['status[]'] + '&';
            }
        }

        if ($.isArray(vm.query['mlsdate[]'])) {
            vm.query['mlsdate[]'].forEach((a) => {
                param += 'mlsdate[]=' + a + '&';
            });
        }
        else {
            if (vm.query['mlsdate[]']) {
                param += 'mlsdate[]=' + vm.query['mlsdate[]'] + '&';
            }
        }

        this.getMLSDetail(param)

        this.filteredReportDetail = this.mlsData

    }
}
</script>
<style scoped>
th>div {
    position: relative;
}

th>div>span {
    white-space: nowrap;
}

th>div>div {
    position: absolute;
}

fa {
    font-size: 18px;
}

th ul {
    border: 1px solid #eee;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    overflow: hidden;
}

.visible {
    max-height: 100px;
    transition: max-height .3s;
}

.invisible {
    max-height: 0px;
    transition: max-height .3s;
}

.rotate-180 {
    transform: rotate(180deg);
    transition: transform .3s;
}

.rotate-360 {
    transform: rotate(360deg);
    transition: transform .3s;
}

th ul li {
    background: #fff;
    padding: 8px 16px;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    text-transform: none;
    font-size: 12px;
    white-space: nowrap;
    height: 32px;
    text-align: left;
    cursor: pointer;
}

th ul li:hover {
    background: #4db7fe;
    color: #fff;
}

th ul li:last-child {
    border-bottom: none;
}

.selected {
    background: #4db7fe;
    color: #fff;
}

a {
    color: #007bff;
}

.report-container {
    padding: 30px 140px;
}

.status-btn {
    background: #EEF4FB;
    border-radius: 25px;
    font-size: 20px;
    line-height: 18px;
    color: #415A8F;
    padding: 10px 30px;
    margin-right: 30px;
    display: inline-block;
}

.status-row {
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: left;
}

.status-line {
    line-height: 5;
}

.summary-table-header-row {
    cursor: pointer;
}

.list-table thead.summary-table-header tr.summary-table-header-row th {
    top: 0;
    padding: 2.5rem 1.5rem;
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.2rem;
    z-index: 98;
    vertical-align: middle;
    position: sticky;
    background: #476e93;
    color: #fff;
    line-height: 24px;
}

.table.list-table tbody tr td {
    font-family: Poppins;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 30px;
    padding: 1.5rem;
    vertical-align: middle;
    border-bottom: 2px solid #545454;
    border-top: none;
}

.print-none {
    cursor: pointer;
    height: fit-content;
    max-width: 50px;
}

.fields-toggle-title {
    color: #13293E;
    font-size: 1.4rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}

.fields-toggle-wrapper {
    zoom: 1.4;
    border: 3px #13293E solid;
    position: absolute;
    right: 70px;
    width: 450px;
    z-index: 999;
    background: #F8F7F7;
    margin-top: 56px;
    padding: 30px;
}

.selected-fields .list-group-item {
    background: #FC5868;
    color: #fff;
}

.fields-toggle-bar {
    border-bottom: 3px solid #13293E;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.list-group-item {
    background: #D9D9D9;
    color: #13293E;
    font-size: 0.9rem;
    border: none;
}

.list-group {
    flex-direction: inherit;
}

.list-group-item {
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

input._red-checkbox {
    accent-color: #FF033E;
    height: 30px !important;
    width: 30px !important;
}

h1 {
    font-size: 3rem;
    font-family: Poppins;
    font-weight: 600;
}

/* MOBILE STYLING */
@media only screen and (max-width: 976px) {

    .print-none {
        width: 100%;
        max-width: 35px;
        height: 40px;
    }

    .report-container {
        padding: 30px 50px;
    }
}

.modal-body {
    justify-content: center;
}

.mls-floorplan {
    color: #4d7ce1;
    text-decoration: underline;
}

.close {
    color: white !important;
}

/* END OF MOBILE STYLING */
</style>