<template>
    <div>
        <b-table :sort-by="$route.path == '/type' ? 'mls_price_date' : 'price_date'" :sort-compare="sortDate" :ref="table_reference" @filtered="onFiltered" responsive="sm" hover small
            :filter-included-fields="filterOn" striped :filter="filter" :current-page="currentPage" :per-page="perPage"
            thead-class="bg-vb-secondary text-light position-sticky" :items="row_data" :fields="header_fields">
            <template #head(checkbox)="data">
                <b-form-group>
                    <b-form-checkbox dis size="lg" @change="onSelectAllRows" v-model="all_rows_selected" variant="danger"
                        class="mr-1 ml-2 _red-checkbox">
                    </b-form-checkbox>
                </b-form-group>
            </template>
            <template #cell(customPlanningData)="data">  
                <template v-if="['developer','developerbc','realtor'].includes(current_user.role)"> 
                    <template v-for="(value, key) in Object.fromEntries(Object.entries(data.value).filter(([k]) => ['REZ','DP'].includes(k)))">
                        <p v-if="value" :key="key"> {{ key }} : {{ value }} </p>
                    </template>
                </template>
                <template v-else>
                    <template v-for="(value, key) in data.value">
                        <p v-if="value" :key="key"> {{ key }} : {{ value }} </p>
                    </template>
                </template>
            </template>
            <template #cell(project_name)="data">
                <h5 class="text-left pl-3">
                    <a class="text-vb-primary" style="font-weight: 500;font-size: 20px;" :href="data.item.link">{{
                    data.value }} </a>
                </h5>
            </template>
            <template #cell(customData)="data">
                <div class="row justify-content-between pl-2 pr-5">
                    <div>
                        <h5 class="text-left pl-3"><a class="text-vb-primary" style="font-weight: 500;font-size: 20px;"
                                :href="data.value.href_link">{{
            data.value.projectName }} <font-awesome-icon :icon="['fas', 'medal']"
                                    v-if="isInCurrentQuarter(data.item.sales_start_date)" /></a></h5>
                        <p class="text-left pl-3 text-vb-primary mb-0 pb-0" style="font-weight: 400; font-size: 18px;">
                            {{
            data.value.project }}</p>
                    </div>
                    <!-- <div>
                        <font-awesome-icon class="_cursor-pointer" :icon="['far', 'heart']" />
                    </div> -->
                </div>
            </template>
            <template #cell(customProjectName)="data">
                <div class="row justify-content-between pl-2 pr-5">
                    <div>
                        <h5 class="text-left pl-3"><a class="text-vb-primary" style="font-weight: 500;font-size: 20px;"
                                :href="data.value.href_link">{{
            data.value.projectName }} <font-awesome-icon :icon="['fas', 'medal']"
                                    v-if="['Selling Soon','Leasing Soon'].includes(data.item.status)" /></a></h5>
                        <p class="text-left pl-3 text-vb-primary mb-0 pb-0" style="font-weight: 400; font-size: 18px;">
                            {{
            data.value.project }}</p>
                    </div>
                    <!-- <div>
                        <font-awesome-icon class="_cursor-pointer" :icon="['far', 'heart']" />
                    </div> -->
                </div>
            </template>
            <template #cell(customTypeStoreyData)="data">
                <p class="pl-3 text-vb-primary mb-0 pb-0" style="font-weight: 400; font-size: 18px;">{{
            data.value.projectType }}</p>
                <p class="pl-3 text-vb-primary mb-0 pb-0" style="font-weight: 400; font-size: 18px;">({{
            data.value.storey ? data.value.storey : '-' }})</p>
            </template>
            <template #cell(customLocationData)="data">
                <p class="text-left pl-3 text-vb-primary mb-0 pb-0" style="font-weight: 400; font-size: 18px;">{{
            data.value.address }}</p>
                <p v-if="data.value.municipality" class="text-left pl-3 text-vb-primary mb-0 pb-0" style="font-weight: 400; font-size: 18px;">{{
            data.value.municipality }}</p>
                <p v-else class="text-left pl-3 text-vb-primary mb-0 pb-0" style="font-weight: 400; font-size: 18px;">{{
            data.value.neighbour }}</p>
            </template>
            <template #cell(field_mls_address_value)="data">
                <span v-if="data.item.field_mls_floor_plan_fid != null" style="cursor: pointer;" class="text-primary"
                    @click="getFloorPlan(data.item.field_mls_floor_plan_fid)"><u>{{ data.value }}</u></span>
                <span v-else>{{ data.value }}</span>
            </template>
            <template #cell(field_mls_list_price_value)="data">
                <span v-b-modal="'mlspop'" @click="$emit('popHistory', data.item)"
                    :style="{ 'color': setColor(data.item.price_backup) }">{{ data.value }}</span>
            </template>
            <template #cell(checkbox)="data">
                <b-form-group>
                    <b-form-checkbox size="lg" variant="danger" v-model="data.item.rowSelected" @change="promptRow(data.item.rowSelected)"
                        class="mr-1 _red-checkbox">
                    </b-form-checkbox>
                </b-form-group>
            </template>
            <template #cell(architect_consultant)="data"> 
                    <h4 v-if="data.value.length > 0">
                        <BBadge variant="vb-secondary" class="m-1" v-for="(item, index) in data.value" :key="index">
                            {{item}}
                        </BBadge>
                    </h4>
                    <p v-else> - </p>
            </template>
            <template #cell(marketing_consultant)="data"> 
                    <h4 v-if="data.value.length > 0">
                        <BBadge variant="vb-secondary" class="m-1" v-for="(item, index) in data.value" :key="index">
                            {{item}}
                        </BBadge>
                    </h4>
                    <p v-else> - </p>
            </template>
            <template #cell(general_contractor)="data"> 
                    <h4 v-if="data.value.length > 0">
                        <BBadge variant="vb-secondary" class="m-1" v-for="(item, index) in data.value" :key="index">
                            {{item}}
                        </BBadge>
                    </h4>
                    <p v-else> - </p>
            </template>
            <!-- Start of UTC Page -->
            <template #cell(mls_beds)="data">
                <strong class="font-weight-bold" v-b-modal="`mls-${listing_type}-` + data.item.cid" v-if="data.value == 's'">Studio</strong>
                <strong class="font-weight-bold" v-b-modal="`mls-${listing_type}-` + data.item.cid" v-else>{{ data.item.mls_beds }}-bed</strong> <br />
                {{ data.item.fp_specs.toLowerCase() != 'none' ? data.item.fp_specs : '' }}
            </template>
            <template #cell(mls_address)="data">
                <u v-if="data.item.mls_floor_plan_feature" class="text-primary"
                    v-b-modal="'mls-fp-' + data.item.mls_floor_plan_feature.fid">{{ data.value }}</u>
                <span v-else>{{ data.value }}</span>
            </template>
            <template #cell(delete)="data">
                <button v-if="user_role == 'admin'" @click="$emit('deleteMLS', data.item.cid)" class="button border-0"
                    style="background: none;"><font-awesome-icon class="text-vb-red _cursor-pointer"
                        :icon="['fas', 'trash']" /></button>

                <button v-else class="border-0 button" style="background: none;" disabled><font-awesome-icon
                        class="text-muted" :icon="['fas', 'trash']" /></button>
            </template>
            <template v-if="listing_type" #cell()="data">
                <span v-b-modal="`mls-${listing_type}-` + data.item.cid"
                    :style="{ color: getDefaultValue(data).color }">{{
                    getDefaultValue(data).data }}</span>
            </template>
            <template v-else #cell()="data">
                <span v-b-modal="'mlspop'" @click="$emit('popHistory', data.item)">{{ getDefaultValue(data).data
                    }}</span>
            </template>
            <!-- End of UTC Page -->
        </b-table>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, watch } from 'vue'
import Vue from 'vue';
import { BModal, VBModal, VBTooltip } from 'bootstrap-vue';   
import { useRouter } from 'vue2-helpers/vue-router'; 
import { useUserStore } from '../../store/UserStore' 
import { BBadge } from 'bootstrap-vue'

const route = useRouter()

Vue.directive('b-modal', VBModal)
Vue.directive('b-tooltip', VBTooltip)

const root = getCurrentInstance();  // same as ctx.root in component

const props = defineProps({
    row_data: [],
    header_fields: [],
    perPage: null,
    currentPage: null,
    filter: null,
    totalRows: null,
    listing_type: null,
    user_role: null,
    table_reference: null,
    filterOn: [],
    project_status:null,
    marketRentalPsfToDouble: null
})

const current_user = ref(useUserStore().current_user)

const model_totalrows = computed({
    get: () => props.totalRows,
    set: (newVal) => {
        emit('updateTotalRows', newVal)
    }
})

const getComputedValue = (list_price, data) => {
    if (!isNaN(data) && parseInt(data) > 0 && list_price > 0)
        return '$' + parseInt(data).toLocaleString('en-US')

    return '-'
}

const sortDate = (a, b, key, sortDesc, formatter, compareOptions, compareLocale) => { 
    const aDate = new Date(a[key])
    const bDate = new Date(b[key]) 
        // if(['sales_start_date', 'mls_price_date', 'price_date'].includes(key)){}
        if(!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())){
            let aToNum = aDate.getTime()
            let bToNum = bDate.getTime()

            return aToNum < bToNum ? -1 : aToNum > bToNum ? 1 : 0
        }    
}
 

const getDefaultValue = (data) => {
    let result = {
        data: '',
        color: ''
    }
    if (data.value.length <= 0 || data.value == null) {
        return result = {
            data: '-',
            color: ''
        }
    } 

    if (['total_avg_price', 'mls_list_price', 'net_price', 'buyer_incentives', 'realtor_incentives', 'total_avg_psf'].includes(data.field.key)) {
        if (!isNaN(data.value) && parseInt(data.value) > 0) {
            if(route.currentRoute.path == '/listing-new' && route.currentRoute.query['status[]'] == 'now_leasing' && ['developer','developerbc'].includes(current_user.value.role))
            {  
                return result = {
                    data: '$' + Number(data.value).toLocaleString('en-US', {maximumFractionDigits: 2}),
                    color: setColor(data.item.price_back_up)
                }
            }else{
                return result = {
                    data: '$' + Math.round(parseInt(data.value)).toLocaleString('en-US'),
                    color: setColor(data.item.price_back_up)
                }
            }
        }

        return result = {
            data: '-',
            color: '-'
        }
    }

    if (['psf'].includes(data.field.key)) {   
        if(props.marketRentalPsfToDouble && props.marketRentalPsfToDouble != null){ 
            return result = {
                    data: '$' + Number(data.value).toFixed(2),
                    color: setColor(data.item.price_back_up)
            }
        }
        
        return result = {
                    data: '$' + Number(Math.round(data.value)),
                    color: setColor(data.item.price_back_up)
        }

    }

    if (['mls_psf'].includes(data.field.key)) {   
        if ((!isNaN(data.value) || parseInt(data.value) > 0 || data.value.length > 0) ) { 
            if(props.project_status != null && ['now selling'].includes(props.project_status.toLowerCase()))
            {  

                if(typeof data.value == 'string' && data.value.includes("$"))
                { 
                    return result = {
                    data: data.value,
                    color: setColor(data.item.price_back_up)
                    }
                }
                else    
                {
                    return result = {
                    data: data.value ? '$' + Math.round(parseInt(data.value)).toLocaleString('en-US') : '-',
                    color: setColor(data.item.price_back_up)
                    }
                }
            }else if(route.currentRoute.path == '/type' && props.project_status.toLowerCase() === 'now leasing' && props.listing_type === 'sold'){ 
               
                let list_price = Number(data.item.mls_list_price)
                let unit_size = Number(data.item.mls_unit_size) 
                if(typeof data.value == 'string' && data.value.includes("$") && data.value.length > 0 && Number(list_price) > 0)
                {
                    return result = {
                    data: '$' + Number(list_price / unit_size).toFixed(2),
                    color: setColor(data.item.price_back_up)
                    }
                }
                
                return result = {
                    data: '-',
                    color: setColor(data.item.price_back_up)
                }
            }
            else
            {   
                if(typeof data.value == 'string' && data.value.includes("$"))
                { 
                    return result = {
                    data: data.value,
                    color: setColor(data.item.price_back_up)
                    }
                }
                return result = {
                    data: '$' + Number(data.value).toFixed(2),
                    color: setColor(data.item.price_back_up)
                }
            }
        }

        return result = {
            data: '-',
            color: '-'
        }
    }

    return result = {
        data: data.value,
        color: ''
    }
}

const all_rows_selected = ref(false)

const emit = defineEmits(['popModal', 'updateTotalRows', 'selectAllRows', 'popHistory','countProjectSelected']);

const getFloorPlan = (data) => {
    emit('popModal', data)
}


const isInCurrentQuarter = (date) => {
    const now = new Date();
    const currentQuarter = Math.floor(now.getMonth() / 3) + 1;
    const salesDate = new Date(date);
    const salesQuarter = Math.floor(salesDate.getMonth() / 3) + 1;

    return (
        salesDate.getFullYear() === now.getFullYear() &&
        salesQuarter === currentQuarter
    );
}
const setColor = (data) => {
    let color = ''
    if (data) {
        switch (data.toLocaleLowerCase()) {
            case 'verified':
                color = "#0b36dc"
                break;

            case 'educated estimated':
                color = "#dc3545"
                break;

            case 'semi-verified':
                color = "#FF6F16"
                break;

            default:
                color = '#000'
                break;
        }
    }
    return color
}

const onFiltered = (filteredItems) => {
    model_totalrows.value = filteredItems.length;
}
const selectableTable = ref(null)
const onSelectAllRows = () => {
    emit('selectAllRows', { selectAll: all_rows_selected.value, tableRef: props.table_reference })
}

const promptRow = (data) => {
    emit('countProjectSelected', data)
}

</script>

<style scoped></style>