<template>
  <div>
    <template v-if="$route.path == '/mls-list'">
        <div class="row">
        <div class="col-3">
            Unit#: {{ data.field_mls_address_value }}
        </div>
        <div class="col-3">
            List Date:
            <b-badge class="bg-vb-secondary text-light" style="font-size: medium">{{
            data.field_mls_list_date_value != '0' ? data.field_mls_list_date_value : '-'
            }}</b-badge>
        </div>
        </div>
        <div class="row">
        <div class="col-3">Beds: {{ data.field_mls_beds_value }}</div>
        <div class="col-3">
            Baths: {{ data.field_mls_baths_value }}
        </div>
        <div class="col-3">
            Unit Size: {{ Math.round(data.field_mls_unit_size_value).toLocaleString() }}
        </div>
        <div class="col-3">FP Specs: {{ data.fp_specs }}</div>
        </div>
        <div class="row mb-3">
        <div class="col-3">View: {{ data.view_orientation }}</div>
        <div class="col-3">Levels: {{ data.floor_levels }}</div>
        <div class="col-3">
            Parking: {{ data.parking ? data.parking : "-" }}
        </div>
        <div class="col-3">
            Stalls: {{ data.stalls ? data.stalls : "-" }}
        </div>
        </div>
    </template>

    <template v-else>
        <div class="row">
        <div class="col-3">
            Unit#: <span v-if="data.mls_floor_plan_feature.fid" v-b-modal="'mls-fp-'+data.mls_floor_plan_feature.fid" class="mls-floorplan">{{data.mls_address}}</span>
        </div>
        <div class="col-3">
            List Date:
            <b-badge class="bg-vb-secondary text-light" style="font-size: medium">{{
            data.mls_list_date != '0' ? data.mls_list_date : '-'
            }}</b-badge>
        </div>
        </div>
        <div class="row">
        <div class="col-3">Beds: {{ data.mls_beds }}</div>
        <div class="col-3">
            Baths: {{ data.mls_baths }}
        </div>
        <div class="col-3">
            Unit Size: {{ Math.round(data.mls_unit_size).toLocaleString() }}
        </div>
        <div class="col-3">FP Specs: {{ data.fp_specs }}</div>
        </div>
        <div class="row mb-3">
        <div class="col-3">View: {{ data.orientation }}</div>
        <div class="col-3">Levels: {{ data.levels }}</div>
        <div class="col-3">
            Parking: {{ data.stalls ? data.stalls : "-" }}
        </div>
        <div class="col-3">
            Stalls: {{ data.mls_parking ? data.mls_parking : "-" }}
        </div>
        </div>
 
    </template>

    <b-table :items="mlsHistory" :fields="header_fields" thead-class="bg-vb-secondary text-light position-sticky" responsive="sm" hover small striped>
        
        <template #cell(mls_psf)="data">  
            {{ data.value > 0 ? '$'+ Math.round(data.value).toLocaleString() : '-' }}
        </template>
        <template #cell(mls_list_price)="data">  
            <span :style="{'color' : setColor(data.item.price_back_up)}">{{ data.value > 0 ? '$'+ Math.round(data.value).toLocaleString() : '-' }}</span>
        </template>
        <template #cell(buyer_incentives)="data">  
            <span :style="{'color' : setColor(data.item.price_back_up)}">{{ data.value > 0 ? '$'+ Math.round(data.value).toLocaleString() : '-' }}</span>
        </template>
        <template #cell()="data">
            {{data.value ? data.value : '-'}}
        </template>
    </b-table>
  </div>
</template>

<script setup lang="ts">
import { BBadge } from 'bootstrap-vue'
import {ref, onMounted} from 'vue';

import { useUserStore } from '../../store/UserStore' 
import { useRouter } from 'vue2-helpers/vue-router'; 

const route = useRouter()
const current_user = ref(useUserStore().current_user)
const props = defineProps({
    data: null,
    mlsHistory: null
});

const header_fields = ref()

const setHeaderFields = () => {
    switch(route.currentRoute.path) {
        case '/mls-list':
            if(current_user.value.role == 'admin'){
                header_fields.value = [
                    { label: "List Price", key: 'mls_list_price'},
                    { label: "Beds" , key: 'mls_beds' },
                    { label: "Bats" , key: 'mls_baths' },
                    { label: "$PSF" , key: 'mls_psf' }, 
                    { label: "Sold Price" , key: 'mls_sold_price' }, 
                    { label: "SP SQFT", key: 'mls_sp_sqft' },
                    { label: "Sold Date", key: 'mls_sold_date' },
                    { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                    { label: 'Collection Type', key: 'collection_type' },
                ]
            }
            else {
                header_fields.value = [
                    { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                    { label: "List Price", key: 'mls_list_price'},
                    { label: "$PSF" , key: 'mls_psf' },
                    { label: "Price Date", key: 'price_date' },
                    { label: "Incentives", key: 'buyer_incentives' },
                    { label: "Net Price", key: 'net_price' },
                    { label: "DOM", key: 'mls_dom' },
                    { label: "Sold Date", key: 'mls_sold_date' },
                ]
            }
        break;

        default:
                header_fields.value = [
                    { label: 'Status', key: 'mls_status', thClass: 'w-small' },
                    { label: "List Price", key: 'mls_list_price'},
                    { label: "$PSF" , key: 'mls_psf' },
                    { label: "Price Date", key: 'mls_price_date' },
                    { label: "Incentives", key: 'buyer_incentives' },
                    { label: "Net Price", key: 'net_price' },
                    { label: "DOM", key: 'mls_dom' },
                    { label: "Sold Date", key: 'mls_sold_date' },
                ]
        break;
    }
}

const setColor = (pb) => {
        let color = ""
        let priceBackUp = ""
        if(route.currentRoute.path == '/mls-list')
            priceBackUp = props.data.field_price_back_up_value 
        else 
            priceBackUp = pb

        switch (priceBackUp) {
            case 'Verified':
            color = "#0b36dc"
            break;
            case 'Educated Estimated':
            color = "#dc3545"
            break;
            case 'Semi-Verified':
            color = "#FF6F16"
            break;
        } 
        return color
}

onMounted(() => {
  setHeaderFields()
})
</script>

<style lang="scss" scoped></style>
