<template>
    <section class="justify-content-center" style="padding:0 10rem;height: max-content; min-height: 100%;">
        <div class="row py-3">
            <div class="col-md-12">
                <template v-if="(project.mls_last_date_active.length !== 0 && typeof project.mls_last_date_active != 'undefined')">
                    <div class="row mt-4 ml-1 justify-content-end align-items-center mb-3"> 
                        <div class="d-flex">
                            <span class="text-white bg-danger p-2 mx-2">Estimated Price</span>
                            <span class="text-white p-2 mx-2" style="background-color:#0b36dc">Verified</span>
                            <span class="text-white p-2 mx-2" style="background-color:#FF6F16">Semi-Verified</span>
                        </div>
                    </div>
                    <div class="row justify-content-between mb-4">
                                            <h1 class="text-left">Active Listings</h1>
                                            <div class="row" v-if="!isAdmin">
                                                <b-button class="mx-1 mx-md-2" variant="vb-tertiary" size="sm" pill @click="refreshResults('active')">UPDATE
                                            TABLE</b-button>
                                                <img class="_field_icon mx-2 mt-10" src="/images/assets/fields.png" @click="custom_header_field = !custom_header_field"/>
                                            </div>
                                        </div>
                                        <div class="fields-toggle-wrapper" v-if="custom_header_field">
                                            <div class="fields-toggle-block selected-fields">
                                                <div class="row justify-content-between">
                                                    <div class="fields-toggle-title">Shown Data Point</div>
                                                    <font-awesome-icon
                                                        style="font-size: 20px;cursor: pointer;position: absolute;top: 12px;right: 12px;"
                                                        class="text-danger" @click="custom_header_field = false" :icon="['fas', 'times-circle']" />
                                                </div>
                                                <draggable class="row" :list="rpdTableList1" group="field"
                                                    @change="fieldsOn" :sort="true"> 
                                                <template v-for="(element, index) in rpdTableList1" >
                                                    <div :class="{'d-none' : ['checkbox'].includes(element.key.toLocaleLowerCase())}" class="list-group-item _cursor-pointer" :key="'element-a123' + element.label"
                                                    >
                                                    {{ index }}. {{ element.label }}</div>
                                                </template> 
                                                </draggable>
                                            </div>
                                            <div class="fields-toggle-bar"></div>
                                            <div class="fields-toggle-block">
                                                <div class="fields-toggle-title">Drag and Drop Data Points</div>
                                                <draggable class="row" :list="rpdTableList2" group="field" :sort="true">
                                                   
                                                    <template v-for="(element, index) in rpdTableList2" >
                                                    <div :class="{'d-none' : ['checkbox'].includes(element.key.toLocaleLowerCase())}" class="list-group-item _cursor-pointer" :key="'element-a123' + element.label"
                                                    >
                                                    {{ element.label }}</div>
                                                </template> 
                                                </draggable>
                                            </div>
                    </div>
                    <template> 
                        <RpdTable id="my-table"        
                            v-model="totalRows.active" 
                            :totalRows="totalRows.active" 
                            :current-page="currentPage.active"
                            :per-page="perPage"
                            :listing_type="'active'"
                            :table_reference="'active-table'"
                            :row_data="mlsActiveSort" 
                            :header_fields="rpdTableList1"
                            :user_role="current_user.role"
                            v-on:selectAllRows="selectAllRows"
                            >
                        </RpdTable> 
                        
                        <div style="margin-bottom: 8rem !important;">
                            <b-pagination
                                v-model="currentPage.active"
                                :total-rows="totalRows.active"
                                :per-page="perPage"
                                prev-text="Prev"
                                first-text="First"  
                                last-text="Last"
                                size="md"
                                >
                            <template #next-text><font-awesome-icon :icon="['fas', 'caret-right']" /></template>
                            <template #prev-text><font-awesome-icon :icon="['fas', 'caret-left']" /></template>
                            </b-pagination> 
                        </div>
                    </template>
                    <b-modal v-for="(d, k) in project.mls_last_date_active" :id="'mls-fp-'+d.mls_floor_plan_feature.fid" hide-footer :key="k">
                        <div v-if="d.mls_floor_plan_feature.fid" class="col-md-4 d-block text-center">
                            <a target="_blank" :href="d.mls_floor_plan_feature.link"><img class='floorplan-pdf' :src="d.mls_floor_plan_feature.preview"></a>
                        </div>
                    </b-modal>
                    <b-modal v-for="(v_o, k_o) in project.mls_data" :id="'mls-active-'+k_o" hide-footer :key="k_o">
                        <div class="row">
                            <div class="col-md-3 text-left h5" style="cursor: pointer;">
                                Unit: <span v-if="v_o[v_o.length-1].mls_floor_plan_feature.fid" v-b-modal="'mls-fp-'+v_o[v_o.length-1].mls_floor_plan_feature.fid" class="mls-floorplan">{{v_o[v_o.length-1].mls_address}}</span>
                                <span v-else>{{v_o[v_o.length-1].mls_address}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Plan Name: <span>{{v_o[v_o.length-1].mls_plan_name}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Beds: <span>{{v_o[v_o.length-1].mls_beds}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-left h5">
                                Baths: <span>{{v_o[v_o.length-1].mls_baths}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Unit Size: <span>{{v_o[v_o.length-1].mls_unit_size}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                View: <span>{{v_o[v_o.length-1].orientation == 'None' ? '' : v_o[v_o.length-1].orientation}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                <span class="add-entry-btn" v-b-modal="'mls-entry-new'" @click="cidSelected=v_o[v_o.length-1].cid">Add Entry</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-left h5">
                                Parking: <span>{{v_o[v_o.length-1].stalls}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Stalls: <span>{{v_o[v_o.length-1].mls_parking}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Locker: <span>{{v_o[v_o.length-1].mls_locker}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-left h5">
                                GST included: <span>{{v_o[v_o.length-1].mls_get_included}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                PID: <span>{{v_o[v_o.length-1].mls_pid}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Levels: <span>{{v_o[v_o.length-1].levels}}</span>
                            </div>
                        </div>
                        <table class="table mt-3">
                            <thead class="fp-header">
                                <th>List date</th>
                                <th>List Price</th>
                                <th>Price Date</th>
                                <th>$PSF</th>
                                <th>DOM</th>
                                <th>SP Sqft</th>
                                <th>Sold Date</th>
                                <th>Status</th>
                                <th>Collection Type</th>
                                <th>FP Specs</th>
                                <th>Approval</th>
                                <th>Edit</th>
                                <th>Delete</th>
                                <th>Backup Text</th>
                                <th>Backup File</th>
                            </thead>
                            <tbody class="fp-body">
                                <tr v-for="(v_i, k_i) in v_o" style="cursor: pointer;" :key="k_i">
                                    <td>{{v_i.mls_list_date}}</td>
                                    <td :style="{'color' : setColor(v_i.price_back_up)}">${{v_i.mls_list_price | numFormat}}</td>
                                    <td>{{v_i.mls_price_date}}</td>
                                    <td v-if="v_i.mls_psf > 0">${{v_i.mls_psf | numFormat}}</td>
                                    <td v-else>-</td>
                                    <td>{{v_i.mls_dom}}</td>
                                    <td v-if="v_i.mls_sp_sqft > 0">${{v_i.mls_sp_sqft | numFormat}}</td><td v-else>-</td>
                                    <td>{{v_i.mls_sold_date}}</td>
                                    <td>{{v_i.mls_status}}</td>
                                    <td>{{v_i.collection_type}}</td>
                                    <td>{{v_i.fp_specs}}</td>
                                    <td v-if="v_i.mls_need_approval == 1" @click="approveMLSEntry(v_i.cid, v_i.entry, k_o, k_i)" style="cursor: pointer;">Approve</td>
                                    <td v-else @click="unApproveMLSEntry(v_i.cid, v_i.entry, k_o, k_i)" style="cursor: pointer;">UnApprove</td>
                                    <td @click="editMLSEntry(v_i.cid, v_i.entry)" style="cursor: pointer;">Edit</td>
                                    <td @click="deleteMLSEntry(v_i.cid, v_i.entry, k_o, k_i)" style="cursor: pointer;">Delete</td>
                                    <td>{{v_i.backup_text}}</td>
                                    <td v-if="v_i.backup_file"><a :href="v_i.backup_file" target="_blank">file</a></td>
                                    <td v-else></td>
                                </tr>
                            </tbody>
                        </table>
                    </b-modal>
                </template>
                <template v-if="(project.mls_last_date_sold !== 0 && typeof project.mls_last_date_sold != 'undefined')">
                    <div class="row justify-content-between mb-4">
                        <h1 class="text-left">Sold Listings</h1>
                        <b-button class="mx-1 mx-md-2" variant="vb-tertiary" size="sm" pill @click="refreshResults('sold')">UPDATE
                                                TABLE</b-button>
                    </div>
                    <template v-if="isAdmin">
                        <table class="table mt-3">
                            <thead class="fp-header">
                                <th @click="soldSort('mls_address')" style="cursor: pointer;">Unit #</th>
                                <th>PID</th>
                                <th @click="soldSort('mls_plan_name')" style="cursor: pointer;">Plan Name</th>
                                <th>List date</th>
                                <th>Price date</th>
                                <th @click="soldSort('mls_list_price')" style="cursor: pointer;">List Price</th>
                                <th>Buyer Incentives</th>
                                <th>Net Price</th>
                                <th @click="soldSort('mls_beds')" style="cursor: pointer;">Beds</th>
                                <th @click="soldSort('mls_baths')" style="cursor: pointer;">Baths</th>
                                <th>Balcony</th>
                                <th>Balcony Area</th>
                                <th @click="soldSort('mls_unit_size')" style="cursor: pointer;">Unit Size</th>
                                <th @click="soldSort('mls_sold_price')" style="cursor: pointer;">Sold Price</th>
                                <th @click="soldSort('mls_sp_sqft')" style="cursor: pointer;">SP Sqft</th>
                                <th>FP Specs</th>
                                <th>Parking</th>
                                <th>Stalls</th>
                                <th>Sold Date</th>
                                <th @click="activeSort('mls_dom')" style="cursor: pointer;">DOM</th>
                                <th>Status</th>
                                <th>View</th>
                                <th>Delete</th>
                            </thead>
                            <tbody class="fp-body">
                                <tr v-for="(d, k) in mlsSoldSort" style="cursor:pointer;" :key="k">
                                    <td v-if="d.mls_floor_plan_feature.fid" v-b-modal="'mls-fp-'+d.mls_floor_plan_feature.fid" class="mls-floorplan">{{d.mls_address}}</td>
                                    <td v-else v-b-modal="'mls-active-'+d.cid">{{d.mls_address}}</td>
                                    <td>{{d.mls_pid}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid" >{{d.mls_plan_name}}</td>
                                    <td>{{d.mls_list_date}}</td>
                                    <td>{{d.mls_price_date}}</td>
                                    <td v-if="d.mls_list_price > 0" v-b-modal="'mls-sold-'+d.cid" >${{d.mls_list_price | numFormat}}</td>
                                    <td v-else>-</td>
                                    <td>${{d.buyer_incentives | numFormat}}</td>
                                    <td>${{ ( d.mls_list_price - d.buyer_incentives) | numFormat}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid" >{{(d.mls_beds > 0 || d.mls_beds === '0' || d.mls_beds == 's' || d.mls_beds == 'jr' ) ? d.mls_beds : '-'}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid" >{{(d.mls_baths > 0 || d.mls_baths === '0') ? d.mls_baths : '-'}}</td>
                                    <td>{{d.balcony}}</td>
                                    <td>{{d.balcony_area}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid" >{{(d.mls_unit_size > 0 || d.mls_unit_size === '0') ? d.mls_unit_size : '-'}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid"  v-if="d.mls_sold_price != '-'">${{d.mls_sold_price | numFormat}}</td><td v-else>-</td>
                                    <td v-b-modal="'mls-sold-'+d.cid"  v-if="d.mls_sp_sqft > 0">${{d.mls_sp_sqft | numFormat}}</td><td v-else>-</td>
                                    <td v-b-modal="'mls-sold-'+d.cid">{{d.fp_specs}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid">{{d.stalls}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid">{{d.mls_parking}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid" >{{d.mls_sold_date}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid" >{{d.mls_dom}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid" >{{d.mls_status}}</td>
                                    <td v-b-modal="'mls-sold-'+d.cid" >{{d.orientation == "None" ? '' : d.orientation}}</td>
                                    <td @click="deleteMLS(d.cid)" style="cursor: pointer;">Delete</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else> 
                        <RpdTable id="sold-listing-table"        
                            v-model="totalRows.sold" 
                            :totalRows="totalRows.sold" 
                            :current-page="currentPage.sold"
                            :per-page="perPage"
                            :listing_type="'sold'"
                            :table_reference="'sold-table'"
                            :row_data="mlsSoldSort" 
                            :header_fields="rpdTableList1"
                            :user_role="current_user.role"
                            v-on:selectAllRows="selectAllRows"
                            >
                        </RpdTable> 
                        
                        <div style="margin-bottom: 8rem !important;">
                            <b-pagination
                                v-model="currentPage.sold"
                                :total-rows="totalRows.sold"
                                :per-page="perPage"
                                prev-text="Prev"
                                first-text="First"  
                                last-text="Last"
                                size="md"
                                >
                            <template #next-text><font-awesome-icon :icon="['fas', 'caret-right']" /></template>
                            <template #prev-text><font-awesome-icon :icon="['fas', 'caret-left']" /></template>
                            </b-pagination> 
                        </div>
                    </template>
                    <b-modal v-for="(d, k) in project.mls_last_date_sold" :id="'mls-fp-'+d.mls_floor_plan_feature.fid" hide-footer :key="k">
                        <div v-if="d.mls_floor_plan_feature.fid" class="col-md-4 d-block text-center">
                            <a target="_blank" :href="d.mls_floor_plan_feature.link"><img class='floorplan-pdf' :src="d.mls_floor_plan_feature.preview"></a>
                        </div>
                    </b-modal>
                    <b-modal v-for="(v_o, k_o) in project.mls_data" :id="'mls-sold-'+k_o" hide-footer :key="k_o">
                        <div class="row">
                            <div class="col-md-3 text-left h5" style="cursor: pointer;">
                                Unit: <span v-if="v_o[v_o.length-1].mls_floor_plan_feature.fid" v-b-modal="'mls-fp-'+v_o[v_o.length-1].mls_floor_plan_feature.fid" class="mls-floorplan">{{v_o[v_o.length-1].mls_address}}</span>
                                <span v-else>{{v_o[v_o.length-1].mls_address}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Plan Name: <span>{{v_o[v_o.length-1].mls_plan_name}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Beds: <span>{{v_o[v_o.length-1].mls_beds}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-left h5">
                                Baths: <span>{{v_o[v_o.length-1].mls_baths}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Unit Size: <span>{{v_o[v_o.length-1].mls_unit_size}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                View: <span>{{v_o[v_o.length-1].orientation == "None" ? '' : v_o[v_o.length-1].orientation}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                <span class="add-entry-btn" v-b-modal="'mls-entry-new'" @click="cidSelected=v_o[v_o.length-1].cid">Add Entry</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-left h5">
                                Parking: <span>{{v_o[v_o.length-1].stalls}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Stalls: <span>{{v_o[v_o.length-1].mls_parking}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Locker: <span>{{v_o[v_o.length-1].mls_locker}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-left h5">
                                GST included: <span>{{v_o[v_o.length-1].mls_get_included}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                PID: <span>{{v_o[v_o.length-1].mls_pid}}</span>
                            </div>
                            <div class="col-md-3 text-left h5">
                                Levels: <span>{{v_o[v_o.length-1].levels}}</span>
                            </div>
                        </div>
                        <table class="table mt-3">
                            <thead class="fp-header">
                                <th>List date</th>
                                <th>List Price</th>
                                <th>Price Date</th>
                                <th>Sold Price</th>
                                <th>SP Sqft</th>
                                <th>Sold Date</th>
                                <th>DOM</th>
                                <th>Status</th>
                                <th>Collection Type</th>
                                <th>FP Specs</th>
                                <th>Approval</th>
                                <th>Edit</th>
                                <th>Delete</th>
                                <th>Backup Text</th>
                                <th>Backup File</th>
                            </thead>
                            <tbody class="fp-body">
                                <tr v-for="(v_i, k_i) in v_o" :key="k_i">
                                    <td>{{v_i.mls_list_date}}</td>
                                    <td :style="{'color' : setColor(v_i.price_back_up)}" v-if="v_i.mls_list_price > 0">${{v_i.mls_list_price | numFormat}}</td>
                                    <td v-else>-</td>
                                    <td>{{v_i.mls_price_date}}</td>
                                    <td v-if="v_i.mls_sold_price != '-'">${{v_i.mls_sold_price | numFormat}}</td><td v-else>-</td>
                                    <td v-if="v_i.mls_sp_sqft > 0">${{v_i.mls_sp_sqft | numFormat}}</td><td v-else>-</td>
                                    <td>{{v_i.mls_sold_date}}</td>
                                    <td>{{v_i.mls_dom}}</td>
                                    <td>{{v_i.mls_status}}</td>
                                    <td>{{v_i.collection_type}}</td>
                                    <td>{{v_i.fp_specs}}</td>
                                    <td v-if="v_i.mls_need_approval == 1" @click="approveMLSEntry(v_i.cid, v_i.entry, k_o, k_i)" style="cursor: pointer;">Approve</td>
                                    <td v-else @click="unApproveMLSEntry(v_i.cid, v_i.entry, k_o, k_i)" style="cursor: pointer;">UnApprove</td>
                                    <td @click="editMLSEntry(v_i.cid, v_i.entry)" style="cursor: pointer;">Edit</td>
                                    <td @click="deleteMLSEntry(v_i.cid, v_i.entry, k_o, k_i)" style="cursor: pointer;">Delete</td>
                                    <td>{{v_i.backup_text}}</td>
                                    <td v-if="v_i.backup_file"><a :href="v_i.backup_file" target="_blank">file</a></td>
                                    <td v-else></td>
                                </tr>
                            </tbody>
                        </table>
                    </b-modal>
                    <b-modal id="mls-entry-new" hide-footer>
                        <b-form v-on:submit.prevent="mlsEntryUpload" class="text-left">
                            <b-form-group id="mls-input-group-status" label="Status:" label-for="mls-upload-status">
                                <b-form-select id="mls-upload-status" v-model="MLSuploadForm.status" :options="mlsStatus"></b-form-select>
                            </b-form-group>
                            <b-form-group id="mls-input-group-list-price" label="List Price:" label-for="mls-upload-list-price">
                                <b-form-input v-model="MLSuploadForm.listPrice" id="mls-upload-list-price" type="text" placeholder="List Price"></b-form-input>
                            </b-form-group>
                            <b-form-group id="input-group-price-backup" label="Price Backup:" label-for="upload-price-backup">
                                <b-form-select id="upload-price-backup" v-model="MLSuploadForm.priceBackup" :options="priceBackupOption"></b-form-select>
                            </b-form-group>
                            <label for="upload-list-date" style="font-size: 1.8rem;">List Price Date</label>
                            <b-form-datepicker id="mls-upload-list-price-date" v-model="MLSuploadForm.listPriceDate" reset-button class="mb-2"></b-form-datepicker>
                            <label for="upload-list-date" style="font-size: 1.8rem;">Sold Date</label>
                            <b-form-datepicker id="mls-upload-sold-date" v-model="MLSuploadForm.soldDate" reset-button class="mb-2"></b-form-datepicker>
                            <label for="upload-backup-file" style="font-size: 1.8rem;">Back-up File:</label>
                            <b-form-group id="mls-input-group-5">
                                <b-form-file
                                    v-model="MLSuploadForm.floorPlan"
                                    :state="Boolean(MLSuploadForm.floorPlan)"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                    ></b-form-file>
                            </b-form-group>
                            <b-form-group style="margin-top: 40px;" id="mls-input-group-backup-text" label="Back-up Text:" label-for="mls-upload-backup-text">
                                <b-form-input v-model="MLSuploadForm.backupText" id="mls-upload-backup-text" type="text" placeholder="Backup Text"></b-form-input>
                            </b-form-group>
                            <b-form-group id="mls-input-group-buyer-incentives" label="Buyer Incentives:" label-for="mls-upload-buyer-incentives">
                                <b-form-input v-model="MLSuploadForm.buyerIncentives" id="mls-upload-buyer-incentives" type="text" placeholder="Buyer Incentives"></b-form-input>
                            </b-form-group>
                            <b-form-group id="mls-input-group-realtor-incentives" label="Realtor Incentives:" label-for="mls-upload-realtor-incentives">
                                <b-form-input v-model="MLSuploadForm.realtorIncentives" id="mls-upload-realtor-incentives" type="text" placeholder="Realtor Incentives"></b-form-input>
                            </b-form-group>
                            <b-button type="submit" variant="primary">Submit</b-button>
                        </b-form>
                    </b-modal>
                </template>
            </div>
        </div>
    </section>
</template>
<script>
    import {BACKEND_URL} from '../variables.js';
    import {BModal, BModalDirective, VBModal, BForm, BFormGroup, 
    BFormInput, BFormSelect, BFormCheckbox, BFormCheckboxGroup,  BButton, BFormFile, BFormDatepicker} from 'bootstrap-vue';
    import { useUserStore } from '../store/UserStore'; 
    import RpdTable from './common/RpdTable.vue'; 
    import {mapStores, mapState} from 'pinia' 
    import draggable from 'vuedraggable'; 

    export default {
        components: { 
            'b-modal': BModal,
            'b-form': BForm,
            'b-form-group': BFormGroup,
            'b-form-input': BFormInput,
            'b-form-select': BFormSelect,
            'b-form-checkbox': BFormCheckbox,
            'b-form-checkbox-group': BFormCheckboxGroup,
            'b-button': BButton,
            'b-form-file': BFormFile,
            'b-form-datepicker': BFormDatepicker,
            RpdTable,
            draggable
        },
        directives: {
            'b-modal': VBModal,
        },
        data() {
            return {
                custom_header_field:false,
                backupModalText:'',
                rpdTableList1:[],
                rpdTableList2:[],
                totalRows:  {
                    active: 1,
                    sold: 1,
                }, 
                currentPage: {
                    active: 1,
                    sold: 1,
                },
                perPage: 10,
                project: [],
                orderAsc: true,
                activeSortBy: 'mls_address',
                cidSelected: 0,
                MLSuploadForm: {
                    listPrice: '',
                    listPriceDate: null,
                    buyerIncentives: '',
                    realtorIncentives: '',
                    backupText: '',
                    files: null,
                    priceBackup: '',
                    soldDate: null
                },
                mlsStatus: [{text: '', value: 11482}, {text: 'Active', value: 8197}, {text: 'Sold', value: 8198}],
                priceBackupOption: [
                    {text: '', value: ''},
                    {text: 'Verified', value: 'Verified'},
                    {text: 'Semi-Verified', value: 'Semi-Verified'},
                    {text: 'Educated Estimated', value: 'Educated Estimated'}
                ],

            }
        },
        methods:{ 
            
        refreshResults(listingType) {
            switch (listingType) {
                case 'active':
                    if(this.project.mls_last_date_active.filter(row => row.rowSelected === true).length > 0)
                        this.project.mls_last_date_active = this.project.mls_last_date_active.filter(row => row.rowSelected === true)  
                    
                    this.totalRows.active = this.project.mls_last_date_active.length
                    break;
                
                case 'sold':
                if(this.project.mls_last_date_sold.filter(row => row.rowSelected === true).length > 0)
                    this.project.mls_last_date_sold = this.project.mls_last_date_sold.filter(row => row.rowSelected === true)  
                    this.totalRows.sold = this.project.mls_last_date_sold.length
                break;
                    
                default:
                    break;
            }
        },
            fieldsOn: function () {
                this.reportFields = []; 
                let count = this.rpdTableList1.filter(x => x.key != 'checkbox').length 
                if (count >= 8) {
                    let poped = this.rpdTableList1.filter(x => x.key != 'checkbox').pop();
                    const index = this.rpdTableList1.indexOf(poped)
                    this.rpdTableList1.splice(index, 1) 
                    this.rpdTableList2.push(poped);
                }else{ 
                    this.rpdTableList1.forEach((val, key) => {
                        this.reportFields.push(val.field);
                    });
                }
            },
            setRpdTableHeader(){ 
                switch(this.current_user.role){
                    case 'admin':
                        
                        this.rpdTableList1 = [
                            { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                            { label: "Unit #", key: 'mls_address', sortable: true },
                            { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                            { label: "Type", key: 'mls_beds', sortable: true},
                            { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                            { label: "List Price", key: 'mls_list_price', formatter: this.roundToWholeNumber, sortable: true },
                            { label: "$PSF", key: 'mls_psf', formatter: this.roundToWholeNumber, sortable: true },
                            { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                            { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                            { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                            { label: "Baths", key: 'mls_baths', sortable: true  },
                            { label: "Parking", key: 'stalls' },
                            { label: "Stalls", key: 'mls_parking' },
                            { label: "List Date", key: 'mls_list_date', sortable: true  },
                            { label: "View Orientation", key: 'orientation', sortable: true  },
                        ]
                    break;

                    default: 
                this.rpdTableList1 = [
                    { label: 'Selected', key: 'checkbox', thClass:'w-small'},
                    { label: "Unit #", key: 'mls_address', sortable: true },
                    { label: "Plan Name", key: 'mls_plan_name',sortable: true, thClass: '_w-15-rem'},
                    { label: "Type", key: 'mls_beds', sortable: true},
                    { label: "Unit Size", key: 'mls_unit_size', sortable: true  },
                    { label: "List Price", key: 'mls_list_price', formatter: this.roundToWholeNumber, sortable: true },
                    { label: "$PSF", key: 'mls_psf', formatter: this.roundToWholeNumber, sortable: true },
                    { label: "Price Date", key: 'mls_price_date',sortable: true  },  
                ]
                
                this.rpdTableList2 = [
                { label: "Balcony", key: 'balcony',sortable: true , thClass: '_w-15-rem'},
                { label: "Balcony Area", key: 'balcony_area',sortable: true , thClass: '_w-15-rem'},
                { label: "Baths", key: 'mls_baths', sortable: true  },
                { label: "Parking", key: 'stalls' },
                { label: "Stalls", key: 'mls_parking' },
                { label: "List Date", key: 'mls_list_date', sortable: true  },
                { label: "View Orientation", key: 'orientation', sortable: true  },
                ]
                    break;
                }
            },
            formatTypeText(value){
            switch (value) {
                case 's':
                    return 'Studio'
                    break;
            
                default:
                    return value
                    break;
            }
        },
        showBackupTextModal(data) {
            this.backupModalText = data
            this.$root.$emit('bv::show::modal', 'backupTextModal')
        },
        selectAllRows({selectAll, tableRef}){
            console.log(selectAll, tableRef)
            switch(tableRef){
                case 'active-table':  
                    Object.keys(this.project.mls_last_date_active) 
                    .forEach(item => {
                    this.project.mls_last_date_active[item].rowSelected = selectAll
                    })
                    break;
                    
                    case 'sold-table':  
                        Object.keys(this.project.mls_last_date_sold) 
                        .forEach(item => {
                        this.project.mls_last_date_sold[item].rowSelected = selectAll
                        })
                        break;
                    
                    case 'other-table':  
                        Object.keys(this.project.mls_last_date_other) 
                        .forEach(item => {
                        this.project.mls_last_date_other[item].rowSelected = selectAll
                        })
                        break;
            }     
        },
            mlsEntryUpload() {
                let formData = new FormData();
                formData.append('status', this.MLSuploadForm.status);
                formData.append('listPrice', this.MLSuploadForm.listPrice);
                formData.append('listPriceDate', this.MLSuploadForm.listPriceDate);
                formData.append('files', this.MLSuploadForm.floorPlan);
                formData.append('backupText', this.MLSuploadForm.backupText);
                formData.append('buyerIncentives', this.MLSuploadForm.buyerIncentives);
                formData.append('realtorIncentives', this.MLSuploadForm.realtorIncentives);
                formData.append('priceBackup', this.MLSuploadForm.priceBackup);
                formData.append('cid', this.cidSelected);
                formData.append('soldDate', this.MLSuploadForm.soldDate);
                formData.append('uid', this.$route.query.uid);
                var vm = this;
                $.ajax({
					type: 'POST',
					url: 'https://www.realpropertydata.ca/backend/api/presale_mls_entry_add_entry',
                    data: formData,
                    processData: false,
                    contentType: false,
				}).done(function(response) {
                    location.reload();
                });
            },
            setColor(data){
                let color = ""
                if(data){  
                    if(data == 'Verified')
                        color = "#0b36dc"

                    if(data == "Educated Estimated")
                        color = "#dc3545"

                    if(data == "Semi-Verified")
                        color = "#FF6F16"
                } 
                return color
            },
            deleteMLSEntry(cid, entry, k1, k2) {
                if (this.project.is_admin) {
                // if (true) {
                    if(confirm("Do you really want to delete this MLS Entry?")){
                        var vm = this;
                        $.ajax({
                            type: 'POST',
                            url: 'https://www.realpropertydata.ca/backend/api/presale_mls_entry_delete',
                            data: {cid: cid, entry: entry},
                        }).done(function(response) {
                            if (response.success) {
                                vm.project.mls_data[k1].splice(k2, 1);
                            }
                        });
                    }
                }
            },
            editMLSEntry(cid, entry) {
                // if (this.project.is_admin) {
                if (true) {
                    let link = 'mls_entry_edit?cid='+cid + '&entry=' + entry +'&project='+this.$route.query.project+'&fid='+this.$route.query.fid+'&uid='+this.$route.query.uid+'&sid='+this.$route.query.sid;
                    window.open(link, '_blank');
                }
            },
            activeSort(column) {
                if (this.activeSortBy == column) {
                    this.orderAsc = !this.orderAsc;
                }
                this.activeSortBy = column;
            },
            soldSort(column) {
                if (this.soldSortBy == column) {
                    this.orderAsc = !this.orderAsc;
                }
                this.soldSortBy = column;
            },
            setColor(data){
                let color = ""
                if(data){  
                    if(data == 'Verified')
                        color = "#0b36dc"

                    if(data == "Educated Estimated")
                        color = "#dc3545"

                    if(data == "Semi-Verified")
                        color = "#FF6F16"
                } 
                return color
            },
        },
        mounted: function() {
            const vm = this;
            this.setRpdTableHeader();
            $.get({
                url: BACKEND_URL + 'api/get_unit_mix_group?uid=' + this.$route.query.uid + '&unit_key=' + this.$route.query.unit_key + '&size_range=' + this.$route.query.size_range,
            }, (data, status) => {
                if (status === 'success') {
                    if(data){
                        let mapped = new Object(data) 
                        for(const key in mapped){
                            if(mapped.hasOwnProperty(key) && ['mls_last_date_active','mls_last_date_sold'].includes(key)) {
                                if(data[key] != null){ 
                                    data[key].forEach(mlsRow => {
                                         mlsRow.rowSelected = false
                                    }) 
                                }
                            }
                        }
                    vm.project = mapped
                    }
                }
            });
        },
        watch: { 
            totalRows(newVal){
                this.totalRows = newVal
            },
            project(newVal){ 
                const vm = this
                if(newVal.mls_last_date_active.length != null) {
                    vm.totalRows.active = newVal.mls_last_date_active.length
                }
                if(newVal.mls_last_date_sold.length != null) {
                    vm.totalRows.sold = newVal.mls_last_date_sold.length
                }
            }
        },
        computed: {
            ...mapState(useUserStore,['current_user']),
            isAdmin: {
                get() {
                    return this.current_user.role == 'admin'
                }
            },
            mlsSoldSort: function() {
                var ob = this.project.mls_last_date_sold;
                var vm = this;
                if (this.project.mls_last_date_sold && this.project.mls_last_date_sold.length < 2) {
                    return ob;
                }
                if (this.soldSortBy) {
                    if (this.soldSortBy == 'mls_plan_name') {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.soldSortBy] > b[vm.soldSortBy]) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.soldSortBy] > b[vm.soldSortBy]) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else if (this.soldSortBy == 'mls_address') {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.soldSortBy].replace('TH', '')) > parseFloat(b[vm.soldSortBy].replace('TH', ''))) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                                else if (a[vm.soldSortBy].includes('TH') && !b[vm.soldSortBy].includes('TH')) {
                                    return -1;
                                }
                                else if (!a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.soldSortBy].replace('TH', '')) > parseFloat(b[vm.soldSortBy].replace('TH', ''))) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                                else if (a[vm.soldSortBy].includes('TH') && !b[vm.soldSortBy].includes('TH')) {
                                    return 1;
                                }
                                else if (!a[vm.soldSortBy].includes('TH') && b[vm.soldSortBy].includes('TH')) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_sold.sort(function (a,b) {
                                if (a[vm.soldSortBy] == '' || a[vm.soldSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.soldSortBy] == '' || b[vm.soldSortBy] == null) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.soldSortBy]) > parseFloat(b[vm.soldSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                }
                return ob;
            },
            mlsActiveSort: function() {
                var ob = this.project.mls_last_date_active;
                var vm = this;
                if (this.project.mls_last_date_active && this.project.mls_last_date_active.length < 2 ) {
                    return ob;
                }
                if (this.activeSortBy) {
                    if (this.activeSortBy == 'mls_plan_name') {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.activeSortBy] > b[vm.activeSortBy]) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.activeSortBy] > b[vm.activeSortBy]) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else if (this.activeSortBy == 'mls_address') {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.activeSortBy].replace('TH', '')) > parseFloat(b[vm.activeSortBy].replace('TH', ''))) {
                                        return 1;
                                    }
                                    else {
                                        return -1;
                                    }
                                }
                                else if (a[vm.activeSortBy].includes('TH') && !b[vm.activeSortBy].includes('TH')) {
                                    return -1;
                                }
                                else if (!a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    if (parseFloat(a[vm.activeSortBy].replace('TH', '')) > parseFloat(b[vm.activeSortBy].replace('TH', ''))) {
                                        return -1;
                                    }
                                    else {
                                        return 1;
                                    }
                                }
                                else if (a[vm.activeSortBy].includes('TH') && !b[vm.activeSortBy].includes('TH')) {
                                    return 1;
                                }
                                else if (!a[vm.activeSortBy].includes('TH') && b[vm.activeSortBy].includes('TH')) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                    else {
                        if (this.orderAsc) {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            });
                        }
                        else {
                            ob = vm.project.mls_last_date_active.sort(function (a,b) {
                                if (a[vm.activeSortBy] == '' || a[vm.activeSortBy] == null) {
                                    return 1;
                                }
                                if (b[vm.activeSortBy] == '' || b[vm.activeSortBy] == null) {
                                    return -1;
                                }
                                if (parseFloat(a[vm.activeSortBy]) > parseFloat(b[vm.activeSortBy])) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                        }
                    }
                }
                return ob;
            }
        }
    }
</script>
<style scoped>
    .add-entry-btn {
        border: 1px solid;
        padding: 10px;
        position: absolute;
        cursor: pointer;
    }
</style>